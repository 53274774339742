@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Hebrew:wght@100;200;300;400;500;600;700&display=swap');

/* @font-face {
  font-family: Fredoka;
  src: url('./assets/fonts/Fredoka/static/Fredoka/Fredoka-Bold.ttf');
  font-weight: 700;
}
@font-face {
  font-family: Fredoka;
  src: url('./assets/fonts/Fredoka/static/Fredoka/Fredoka-Light.ttf');
  font-weight: 300;
}
@font-face {
  font-family: Fredoka;
  src: url('./assets/fonts/Fredoka/static/Fredoka/Fredoka-Medium.ttf');
  font-weight: 500;
}
@font-face {
  font-family: Fredoka;
  src: url('./assets/fonts/Fredoka/static/Fredoka/Fredoka-Regular.ttf');
  font-weight: 400;
}
@font-face {
  font-family: Fredoka;
  src: url('./assets/fonts/Fredoka/static/Fredoka/Fredoka-SemiBold.ttf');
  font-weight: 600;
} */

/* scrollbar */
/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e6fffb;
  border-radius: 18px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #87e8de;
  border-radius: 18px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #006d75;
}